<template>
    <div class="CarListItem grid grid-cols-1 sm:grid-cols-2 bg-gray-700 rounded-md p-3 my-4 shadow-md gap-3">
        <div class="middle text-center sm:text-left">
            <p class="headline">{{name}}</p>
            <p class="seats input-helper-text">{{shortname}}</p>
        </div>
        <div class="right my-auto justify-self-center sm:justify-self-end sm:w-max w-full">
            <button @click="selectItem" class="button-filled sm:w-max w-full">Auswählen <font-awesome-icon icon="arrow-alt-circle-right" class="icon"/></button>
        </div>
    </div>
</template>

<script>
export default {
  name: "CompanyListItem",
  props: {
    index: Number,
    id: String,
    name: String,
    city: String,
    shortname: String
  },
  methods: {
    selectItem() {
      this.$emit('selectItem', this.index)
    }
  }
};
</script>
