<template>
  <div id="modalRegistrationRecommendCompany" class="modal">
    <div class="modal-background"></div>
    <!-- Modal content -->
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h2>Betrieb nicht gefunden?</h2>
          <span class="close" @click="close">&times;</span>
        </div>
        <div class="modal-body">
          <div class="text-center">
            <p>Die Betriebsabläufe können in jedem Verkehrsbetrieb sehr unterschiedlich sein - genau so wie Deine Anforderungen an den StadtbahnfahrerClub. Schreib uns gerne und erzähl uns, mit welchen Anwendungen wir Deinen Arbeitsalltag optimieren können.</p>
            <a href="https://forms.gle/8cxwznZ8JR51N7Vq7" target="_newTab" class="button-filled m-2">Kontaktiere uns <font-awesome-icon icon="paper-plane" class="" /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  data() {
    return {
      show: this.showModal,
    };
  },
  props: {
    image: String,
    showModal: false,
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>




