<template>
    <vue-hcaptcha sitekey="3e251ea3-6db0-4aa1-b668-ae4d255f2a3e"></vue-hcaptcha>
</template>

<script>
  import VueHcaptcha from '@hcaptcha/vue-hcaptcha';

  export default {
    name: 'HCaptcha',
    components: { VueHcaptcha }
  }
</script>