<template>
  <div id="Registration">
    <TheHeader></TheHeader>
    <div class="px-3 py-3  bg-lines bg-cover bg-fixed bg-center">
      <div class="md:container md:mx-auto min-h-screen">
        <canvas id="canvas"></canvas>

        <div class="text-center">
          <img class="block sm:hidden h-40 w-auto mx-auto" src="@/assets/logo_border.png" alt="Workflow" />
          <!-- <h1 class="brand-color-dark text-shadow">JOIN THE CLUB</h1> -->
          <p class="text-4xl sm:text-7xl font-bold uppercase p-1 pt-4 text-shadow">Join the <span class="label_club text-blue-900 stroke-1 sm:stroke-2 text-stroke">club</span>.</p>
          <div class="badge badge-brand" v-show="step > 0">
            <font-awesome-icon icon="subway" class="icon" style="color: white" />
            Fahrdienst
          </div>
        </div>
        <div class="space"></div>

        <!-- <div class="notification" v-if="step == 0">
          <img src="@/assets/diamond_brand.svg" />

          <hr class="line" /> 

          <p class="text">
            Der StadtbahnfahrerClub ist ausschließlich für Personal im
            Fahrbetrieb.<br />Für die Registrierung ist ggf. ein Nachweis
            erforderlich.
          </p>
          <button class="button" @click="nextStep()">Verstanden</button>
        </div> -->

       

<!-- .box-selection-option {
    padding: 32px;

    background-color: #101218;
    border-radius: 8px;
    flex: 1 1 auto;
    margin: auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}





.box-selection-option .line {
    width: 150px;
    margin: 12px;
}

.box-selection-option .icon {
    height: 60px;
    width: 60px;
}

.box-selection-option p {
    font-size: 1.2rem;
}

 .box-selection {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    row-gap: 20px;
    column-gap: 20px;
    max-width: 80%;
    margin: auto;
} -->

        <div id="beta-only" v-if="step == -1">
          <p class="text-xl p-8 text-center text-shadow">Registriere Dich mit Deiner Beta-Einladung</p>

          <div class="registration_complete p-3 sm:p-12 text-center m-auto " >
          <div class="bg-card-content rounded-md w-full sm:w-max mx-auto p-3 sm:p-16">
            <p class="mx-auto text-6xl mb-2">📩</p>
            <p class="text text-xl mb-2">
              Beta<br />
              Einladung annehmen
            </p>
            <div v-if="isFailed == 0 && !isLoadingBetaCheck">
              <div class="text-center mb-1">
                <label class="block mb-1" for="forms-labelOverInputCode">E-Mail Adresse</label>
                <input @input="stepAccessChecker" class="text-center text-black w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline" type="text" value=""  placeholder="" name="beta-invite-email" autocomplete="off" required v-model.trim="form.betaEmail"/>
              </div>
              <div class="text-center mb-1">
                <label class="block mb-1" for="forms-labelOverInputCode">Einladungscode</label>
                <input @input="stepAccessChecker" class="text-center text-black w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline" type="text" value=""  placeholder="" name="beta-invite-code" autocomplete="off" required v-model.trim="form.betaCode"/>
              </div>
              <div class="flex mt-3 justify-center">
                <!-- <HCaptcha @verify="captchaOnVerify" @expired="captchaOnExpired" @error="captchaOnError" /> -->
                <vue-hcaptcha sitekey="3e251ea3-6db0-4aa1-b668-ae4d255f2a3e" @verify="captchaOnVerify" @expired="captchaOnExpired" @error="captchaOnError"></vue-hcaptcha>
              </div>
              <!-- <a href="dashboard" class="button">Zum Dashboard</a> -->
              <button :disabled="stepAccessDisabled" @click="checkBetacode()" class="button-filled my-4 w-full">Code prüfen <font-awesome-icon icon="arrow-alt-circle-right" class="icon"/></button>
              <p class="text text-sm text-gray-300">
                <small>Du kannst Dich nur registrieren, wenn Du eine Beta-Einladung erhalten hast.<br><a class="link" href="https://stadtbahnfahrer.club">Hier</a> kannst Du Dich zur Beta-Warteliste eintragen.</small>
              </p>
            </div>
            <div v-else-if="isFailed == 0 && isLoadingBetaCheck">
              <div class="text-center">
                <font-awesome-icon icon="spinner" class="icon" pulse />
              </div>
            </div>
            <div v-else>
              <p v-if="isFailed == 801">Ungültige Einladung. Prüfe Deine E-Mail Adresse und Deinen Einladungscode. Schreibe uns gerne, falls Du Hilfe brauchst.</p>
              <p v-else>Ein Problem ist aufgetreten. Versuche es bitte später erneut.</p>
              <button @click="isFailed = 0" class="button-filled my-4 w-full">Zurück</button>
            </div>
          </div>
        </div>
          <!--
          <div class="box-selection  items-center grid grid-cols-1 sm:grid-cols-1 gap-5 gap-y-5 gap-x-5 m-auto mb-5">

            <div class="card rounded-lg bg-brand flex flex-col justify-between h-full">
              <font-awesome-icon icon="subway" class="icon" />
              <hr class="card-line w-6/12 mx-auto" />
              <strong class="card-title text-center p-3 text-lg font-bold">Fahrdienst</strong>
              <button @click="nextStep()" to="/tauschboerse" class="button-in-card"
                ><span>Auswählen <font-awesome-icon icon="arrow-alt-circle-right" class="" /></span></button
              >
            </div>
          </div>
          <div class="box-selection-option sm:text-left text-center p-8 bg-gray-800 rounded-md flex-auto m-auto flex flex-col sm:flex-row gap-3  justify-between  items-center w-full h-full">
            <p><span class="font-bold">Du bist in einem anderen Bereich tätig?</span><br>Sag uns, wie wir Deinen Arbeitsalltag erleichtern können.</p>
            <button class="button-filled w-full sm:w-min" @click="nextStep()">Kontaktiere uns <font-awesome-icon icon="paper-plane" class="" /></button>
          </div>
          -->
        </div>


        <div id="job-selector" v-if="step == 0">
          <p class="text-xl p-8 text-center text-shadow">In welchem Bereich bist Du tätig?</p>

          <div class="box-selection  items-center grid grid-cols-1 sm:grid-cols-1 gap-5 gap-y-5 gap-x-5 m-auto mb-5">

            <div class="card rounded-lg bg-brand flex flex-col justify-between h-full">
              <font-awesome-icon icon="subway" class="icon" />
              <hr class="card-line w-6/12 mx-auto" />
              <strong class="card-title text-center p-3 text-lg font-bold">Fahrdienst<br>(Stadtbahnfahrer:in)</strong>
              <button @click="nextStep()" to="/tauschboerse" class="button-in-card"
                ><span>Auswählen <font-awesome-icon icon="arrow-alt-circle-right" class="" /></span></button
              >
            </div>
            <!--

              >>>>>sm:grid-cols-2 <<<<<<


            <div class="card rounded-lg bg-brand flex flex-col justify-between h-full">
              <font-awesome-icon icon="phone-alt" class="icon" />
              <hr class="card-line w-6/12 mx-auto" />
              <strong class="card-title text-center p-3 text-lg font-bold">Fahrdienstleitung</strong>
              <button to="/tauschboerse" class="button-in-card"
                ><span>Auswählen <font-awesome-icon icon="arrow-alt-circle-right" class="" /></span></button
              >
            </div>
            <div class="card rounded-lg bg-brand flex flex-col justify-between h-full">
              <font-awesome-icon icon="clipboard-list" class="icon" />
              <hr class="card-line w-6/12 mx-auto" />
              <strong class="card-title text-center p-3 text-lg font-bold">Dienstplanerstellung</strong>
              <button to="/tauschboerse" class="button-in-card"
                ><span>Auswählen <font-awesome-icon icon="arrow-alt-circle-right" class="" /></span></button
              >
            </div>
            <div class="card rounded-lg bg-brand flex flex-col justify-between h-full">
              <font-awesome-icon icon="tools" class="icon" />
              <hr class="card-line w-6/12 mx-auto" />
              <strong class="card-title text-center p-3 text-lg font-bold">Werkstatt</strong>
              <button to="/tauschboerse" class="button-in-card"
                ><span>Auswählen <font-awesome-icon icon="arrow-alt-circle-right" class="" /></span></button
              >
            </div>
            -->


            <!-- <div class="box-selection-option p-8 bg-gray-800 rounded-md flex-auto m-auto flex flex-col justify-center items-center w-full">
              <font-awesome-icon icon="subway" class="icon" />
              <hr class="line" />
              <p>Fahrdienst</p>
              <button class="button-filled mt-2" @click="nextStep()">Auswählen <font-awesome-icon icon="arrow-alt-circle-right" class="" /></button>
            </div>
            <div class="box-selection-option p-8 bg-gray-800 rounded-md flex-auto m-auto flex flex-col justify-center items-center w-full">
              <font-awesome-icon icon="phone-alt" class="icon" />
              <hr class="line" />
              <p>Fahrdienstleitung</p>
              <button class="button-filled" @click="nextStep()">Auswählen <font-awesome-icon icon="arrow-alt-circle-right" class="" /></button>
            </div>
            <div class="box-selection-option p-8 bg-gray-800 rounded-md flex-auto m-auto flex flex-col justify-center items-center w-full">
              <font-awesome-icon icon="clipboard-list" class="icon" />
              <hr class="line" />
              <p>Dienstplanerstellung</p>
              <button class="button-filled" @click="nextStep()">Auswählen <font-awesome-icon icon="arrow-alt-circle-right" class="" /></button>
            </div>
            <div class="box-selection-option p-8 bg-gray-800 rounded-md flex-auto m-auto flex flex-col justify-center items-center w-full">
              <font-awesome-icon icon="tools" class="icon" />
              <hr class="line" />
              <p>Werkstatt</p>
              <button class="button-filled" @click="nextStep()">Auswählen <font-awesome-icon icon="arrow-alt-circle-right" class="" /></button>
            </div> -->
          </div>
          <div class="box-selection-option sm:text-left text-center p-8 bg-gray-800 rounded-md flex-auto m-auto flex flex-col sm:flex-row gap-3  justify-between  items-center w-full h-full">
            <p><span class="font-bold">Du bist in einem anderen Bereich tätig?</span><br>Sag uns, wie wir Deinen Arbeitsalltag erleichtern können:<br><i><a href="mailto:hallo@stadtbahnfahrer.club" class="link">hallo@stadtbahnfahrer.club</a></i></p>
            <!-- <button class="button-filled w-full sm:w-min" @click="nextStep()">Kontaktiere uns <font-awesome-icon icon="paper-plane" class="" /></button> -->
          </div>
        </div>

        <div class="registration_complete p-3 sm:p-12 text-center m-auto " v-if="step == 6">
          <div class="bg-card-content rounded-md w-full sm:w-max mx-auto p-3 sm:p-6">
            <img src="@/assets/clubcard.png" class="mx-auto w-96" />
            <!-- <p class="mx-auto text-6xl my-2">👋</p> -->
            <p class="headline text-2xl">{{ form.firstname }}</p>
            <hr class="line my-2 mx-auto p-0 border border-blue-900 border-solid w-32" />
            <p class="text text-xl">
              WELCOME<br />
              TO THE CLUB
            </p>
            <!-- <a href="dashboard" class="button">Zum Dashboard</a> -->
            <a href="dashboard" class="button-filled my-4 w-max">Zum Dashboard <font-awesome-icon icon="arrow-alt-circle-right" class="icon"/></a>
            <p class="text text-sm text-gray-300">
              <small>Keine Sorge, bei uns gibt es nur digitales Konfetti 😏</small>
            </p>
          </div>
        </div>

        <div v-if="step > 0 && step < 6">
          <!-- <div class="notification">
            <img src="@/assets/diamond_brand.svg" />
            <p class="headline">JOIN THE CLUB</p>
          </div> -->

          <!-- .content-card .card-header-flex, .nav-content-container .card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 1.0rem;
    padding-right: 1.0rem;
} -->

          <div class="content-card card-fullscreen flex-1 mt-4 bg-gray-800 rounded-md shadow-md w-full flex flex-col justify-between">
            <div class="card-header grid grid-cols-2 sm:flex flex-col sm:flex-row flex-wrap gap-2 justify-around items-center px-4 py-6 bg-gray-900 rounded-t-md">
              <!-- <div class="card-header-flex  space"> -->
                <div class="card-header-flex space-x-2 flex flex-row sm:justify-between justify-center items-center px-4" @click="step = 1">
                  <div class="circle" v-bind:class="{ active: step == 1 }">1</div>
                  <div class="title" v-bind:class="{ active: step == 1 }">
                    Betrieb
                  </div>
                </div>
                <div class="card-header-flex space-x-2 flex flex-row sm:justify-between justify-center items-center px-4" @click="step = 2">
                  <div class="circle" v-bind:class="{ active: step == 2 }">2</div>
                  <div class="title" v-bind:class="{ active: step == 2 }">
                    Konto
                  </div>
                </div>
                <div class="card-header-flex space-x-2 flex flex-row sm:justify-between justify-center items-center px-4" @click="step = 3">
                  <div class="circle" v-bind:class="{ active: step == 3 }">3</div>
                  <div class="title" v-bind:class="{ active: step == 3 }">
                    Persönliche<br />Informationen
                  </div>
                </div>
                <div class="card-header-flex space-x-2 flex flex-row sm:justify-between justify-center items-center px-4" @click="step = 4">
                  <div class="circle" v-bind:class="{ active: step == 4 }">4</div>
                  <div class="title" v-bind:class="{ active: step == 4 }">
                    Verifizierung
                  </div>
                </div>
                <div class="card-header-flex space-x-2 flex flex-row sm:justify-between justify-center items-center px-4">
                  <div class="circle" v-bind:class="{ active: step == 5 }">5</div>
                  <div class="title" v-bind:class="{ active: step == 5 }">
                    Registrierung<br />abschließen
                  </div>
                </div>
              <!-- </div> -->
            </div>
            <div class="card-content p-6">
              <div class="list" v-if="step == 3">
                <p class="text-center"><b>Persönliche Informationen</b></p>
                <p class="text-center">
                  Manche Funktionen unserer Plattform erfordern persönliche
                  Informationen von Dir.<br />Achte bitte auf die Korrektheit der
                  Daten. Schreibe Deinen Vor- und Nachnamen vollständig aus.
                </p>

                <div class="my-6 grid grid-cols-1 sm:grid-cols-2 gap-2">
                  <div class="text-center">
                    <label class="block mb-1" for="forms-labelOverInputCode">Vorname</label>
                    <input @input="stepPersonalChecker" class="text-center text-black w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline" type="text" value="" name="firstname" autocomplete="given-name" required v-model.trim="form.firstname"/>
                  </div>

                  <div class="text-center">
                    <label class="block mb-1" for="forms-labelOverInputCode">Nachname</label>
                    <input @input="stepPersonalChecker" class="text-center text-black w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline" type="text" value="" name="lastname" autocomplete="family-name" required v-model.trim="form.lastname"/>
                  </div>
                </div>

                <!-- <div class="row">
                  <div class="col-6 text-center">
                    <label>Vorname</label>
                    <input
                      type="text"
                      class="text-center"
                      value=""
                      v-model.trim="form.firstname"
                    />
                  </div>

                  <div class="col-6 text-center">
                    <label>Nachname</label>
                    <input
                      type="text"
                      class="text-center"
                      value=""
                      v-model.trim="form.lastname"
                    />
                  </div>
                </div> -->

                <div class="mb-6">
                  <p class="text-center input-helper-text" v-if="!showMoreInfos">
                    <font-awesome-icon icon="info-circle" class="icon" /> Diese
                    Informationen werden nur mit anderen Nutzern geteilt, wenn es
                    zwingend erforderlich ist.<br /><a
                      @click="showMoreInfos = !showMoreInfos"
                      class="link"
                      >Mehr erfahren</a
                    >
                  </p>

                  <p class="text-center input-helper-text" v-if="showMoreInfos">
                    <font-awesome-icon icon="info-circle" class="icon" /> Deine
                    persönlichen Informationen werden nur mit anderen Nutzern
                    geteilt, wenn es für eine Funktion erforderlich ist. Es gibt
                    kein öffentliches Nutzerprofil über Dich - wir sind kein
                    soziales Netzwerk. Wir teilen auch keine Informationen mit
                    Deinem Arbeitgeber ohne Dein Wissen. Achte bitte darauf, dass
                    Deine Eingaben korrekt und vollständig sind. Sie können später
                    nur noch über unseren Support geändert werden.
                  </p>
                </div>

                <button
                  @click="nextStep()"
                  class="button-filled text-center btn-fullsize w-full" :disabled="stepPersonalDisabled"
                >Zum nächsten Schritt
                  &nbsp;<font-awesome-icon icon="chevron-right" class="icon" />
                </button>
              </div>

              <div class="list" v-if="step == 2">
                <p class="text-center"><b>Konto</b></p>
                <p class="text-center">
                  Gebe Deine E-Mail Adresse ein und wähle ein sicheres Passwort mit mindestens 8 Zeichen.
                </p>

                <div class="my-6 grid grid-cols-1 sm:grid-cols-2 gap-2">
                  <div class="text-center">
                    <label class="block mb-1" for="forms-labelOverInputCode">E-Mail Adresse</label>
                    <input disabled="disabled" @input="step2checker" class="text-center text-black w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline" type="email" value="" autocomplete="email" name="email" required v-model.trim="form.email"/>
                  </div>

                  <div class="text-center">
                    <label class="block mb-1" for="forms-labelOverInputCode">E-Mail Adresse wiederholen</label>
                    <input disabled="disabled" @input="step2checker" class="text-center text-black w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline" type="email" value="" autocomplete="email" name="email-repeat" required v-model.trim="form.emailRepeat" />
                  </div>
                </div>

                <div class="my-6 grid grid-cols-1 sm:grid-cols-2 gap-2">
                  <div class="text-center">
                    <label class="block mb-1" for="forms-labelOverInputCode">Passwort</label>
                    <input @input="step2checker" class="text-center text-black w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline" type="password" value="" autocomplete="new-password" name="password" required v-model.trim="form.password"/>
                  </div>

                  <div class="text-center">
                    <label class="block mb-1" for="forms-labelOverInputCode">Passwort wiederholen</label>
                    <input @input="step2checker" class="text-center text-black w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline" type="password" value="" autocomplete="new-password" name="password-repeat" required v-model.trim="form.passwordRepeat"/>
                  </div>
                </div>


                <button
                  @click="nextStep()"
                  class="button-filled text-center btn-fullsize w-full" :disabled="step2disabled"
                >Zum nächsten Schritt
                  &nbsp;<font-awesome-icon icon="chevron-right" class="icon" />
                </button>


                <!-- <div class="row">
                  <div class="col-6 text-center">
                    <label>E-Mail Adresse</label>
                    <input
                      name="email"
                      type="email"
                      class="text-center"
                      value=""
                      v-model.trim="form.email"
                    />
                  </div>

                  <div class="col-6 text-center">
                    <label>Passwort</label>
                    <input
                      name="password"
                      type="password"
                      class="text-center"
                      value=""
                      v-model.trim="form.password"
                    />
                  </div>
                </div> -->
              </div>

              <div class="list" v-if="step == 1">
                <p class="text-center"><b>Betrieb auswählen</b></p>

                

                <div class="col-12 text-center">
                  <!--
                  //NOT AV IN BETA
                  <input class="text-center text-black w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline" type="text" placeholder="Wo arbeitest Du…?" v-model.trim="searchCompany" @input="searchForCompany"/>
                  -->

                  <div v-if="companySearchIsLoading">
                    <font-awesome-icon icon="spinner" class="icon" pulse />
                  </div>

                  <div v-else>
                    <div
                      v-for="(result, index) in companySearchResult"
                      :key="result.id"
                    >
                      <CompanyListItem
                        :index="index"
                        :name="result.name"
                        :shortname="result.shortname"
                        @selectItem="selectCompany"
                      ></CompanyListItem>
                    </div>

                    <div
                      v-if="
                        companySearchResult.length == 0 &&
                        searchCompany.length > 0
                      "
                    >
                      Kein Ergebnis gefunden für "<i>{{ searchCompany }}</i
                      >".
                    </div>
                    <!--
                    //NOT AV IN BETA
                    <br><a class="link" @click="showModal">Betrieb nicht gefunden?</a>
                    -->
                  </div>

                  

                  <ModalRegistrationRecommendCompany
                    v-show="isModalVisible"
                    @close="closeModal"
                  />
                </div>
              </div>

              <div class="list" v-if="step == 4">
                <p class="text-center"><b>Verifizierung</b></p>
                <p class="text-center">
                  Der StadtbahnfahrerClub ist ausschließlich für Personal im
                  Fahrbetrieb.
                </p>
                <!-- <p class="text-center input-helper-text">
                  Dienstausweis-Dokumente werden nach der
                  Verifizierung <b>gelöscht</b>.
                </p> -->

                <div class="my-6 grid grid-cols-1 sm:grid-cols-2 gap-2">
                  <div class="text-center">
                    <label class="block mb-1 font-bold" for="forms-labelOverInputCode">Vorname</label>
                    <input @input="stepVerifyChecker" class="text-center text-black w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline" type="text" value="" name="firstname" autocomplete="given-name" required v-model.trim="form.firstname"/>
                  </div>

                  <div class="text-center">
                    <label class="block mb-1 font-bold" for="forms-labelOverInputCode">Nachname</label>
                    <input @input="stepVerifyChecker" class="text-center text-black w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline" type="text" value="" name="lastname" autocomplete="family-name" required v-model.trim="form.lastname"/>
                  </div>
                </div>

                <!-- <div class="text-center">
                  <label class="block mb-1" for="forms-labelOverInputCode">Vorname</label>
                  <input class="text-center w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline" type="text" value="" v-model.trim="form.firstname"/>
                </div>

                <div class="text-center">
                  <label class="block mb-1" for="forms-labelOverInputCode">Nachname</label>
                  <input class="text-center w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline" type="text" value="" v-model.trim="form.lastname"/>
                </div> -->

                <div class="text-center my-6">
                  <label class="block mb-1 font-bold" for="forms-labelOverInputCode">Personalnummer</label>
                  <input @input="stepVerifyChecker" class="text-center text-black w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline" type="text" value="" v-model.trim="form.staffnumber"/>
                </div>

                <!-- <div class="row">
                  <div class="col-6 text-center">
                    <label>Vorname</label>
                    <input
                      name="firstname"
                      type="text"
                      class="text-center"
                      value=""
                      v-model.trim="form.firstname"
                    />
                  </div>

                  <div class="col-6 text-center">
                    <label>Nachname</label>
                    <input
                      name="lastname"
                      type="text"
                      class="text-center"
                      value=""
                      v-model.trim="form.lastname"
                    />
                  </div>
                </div> -->

                <!-- <div class="row">
                  <div class="col-12 text-center">
                    <label>Personalnummer</label>
                    <input
                      type="text"
                      class="text-center"
                      value=""
                      v-model.trim="form.staffnumber"
                    />
                  </div>
                </div> -->

                <div class="mb-6 p-2 bg-gray-700 rounded-md">

                  <p class="text-center font-bold">Dienstausweis</p>

                  <!-- <div class="my-6 grid grid-cols-1 sm:grid-cols-2 gap-2">
                    <div class="col-6 text-center">
                      <label class="block mb-1 font-bold">Vorderseite</label>
                      <input
                        type="file"
                        id="file-input"
                        class="text-center"
                        value=""
                      />
                    </div>

                    <div class="col-6 text-center">
                      <label class="block mb-1 font-bold">Rückseite</label>
                      <input type="file" class="text-center" value="" />
                    </div>
                  </div> -->

                  <!-- <p class="space text-center">
                    <button class="btn-designless" @click="nextStep()">
                      Dienstausweis später hochladen
                    </button>
                  </p> -->

                  <p class=" mx-auto text-center mt-2 mb-1">
                  <font-awesome-icon icon="id-card" class="icon text-4xl" />
                  </p>

                  <p class="space text-center ">
                    
                    Nach Abschluss der Registrierung benötigen wir Deinen Dienstausweis.<br>
                    Dein Konto ist bis zur vollständigen Verifizierung nur eingeschränkt nutzbar.<br />Die
                    Überprüfung wird einige Tage in Anspruch nehmen.
                  </p>
                </div>

                <button
                  @click="nextStep()"
                  class="button-filled text-center btn-fullsize w-full" :disabled="stepVerifyDisabled"
                >Zum nächsten Schritt
                  &nbsp;<font-awesome-icon icon="chevron-right" class="icon" />
                </button>
              </div>

              <div class="space"></div>

              <div class="space"></div>

              <!-- <div class="list space">
                <button
                  v-if="step < 5 && step != 1"
                  @click="nextStep()"
                  class="button-filled text-center btn-fullsize w-full"
                >
                  <span v-if="step < 4">Zum nächsten Schritt</span
                  ><span v-if="step > 3">Fast geschafft</span>
                  &nbsp;<font-awesome-icon icon="chevron-right" class="icon" />
                </button>
              </div> -->
              <div class="list text-center" v-if="step == 5">
                <p class="text-center"><b>Registrierung abschließen</b></p>

                <div class="my-6">
                  <p class="text-center mb-2 text-lg">
                    <span v-if="form.firstname" class="font-bold">{{ form.firstname }},</span> Du bist
                    einen Klick entfernt Dich kostenfrei für den <span class="brand-color-dark">Club der Zukunft</span> zu
                    registrieren.
                  </p>

                  <p>
                    <label class="checkbox-container space">
                      <input type="checkbox" @change="stepCompleteChecker" v-model="form.termsOfUse" />
                      <span class="checkmark"></span>

                      Ich habe die
                      <a href="https://stadtbahnfahrer.club/nutzungsbedingungen" target="_newTab" class="link"
                        >Nutzungsbedingungen</a
                      >
                      gelesen und akzeptiere sie.
                    </label>
                  </p>

                  <p>
                    <label class="checkbox-container space">
                      <input type="checkbox" @change="stepCompleteChecker" v-model="form.privacyTerms" />
                      <span class="checkmark"></span>
                      Ich habe die
                      <a
                        href="https://stadtbahnfahrer.club/datenschutz"
                        target="_newTab"
                        class="link"
                        >Datenschutzbestimmungen</a
                      >
                      gelesen und akzeptiere sie.
                    </label>
                  </p>

                  <p>
                    <label class="checkbox-container space">
                      <input type="checkbox" @change="stepCompleteChecker" v-model="form.staffcheck" />
                      <span class="checkmark"></span>
                      Hiermit bestätige ich, dass ich nachweislich für
                      <span v-if="form.companyIndex > -1"
                        >den Verkehrsbetrieb
                        <i>{{ companySearchResult[form.companyIndex].name }}</i></span
                      >
                      <span v-else>einen Verkehrsbetrieb</span>
                      arbeite.
                    </label>
                  </p>

                </div>

                <button
                  @click="lastStep()"
                  class="button-filled text-center btn-fullsize w-full"
                  :disabled="stepCompleteDisabled || isLoadingRegister"
                >
                  <span v-show="isLoadingRegister"><font-awesome-icon icon="spinner" class="icon" pulse /></span> JOIN THE CLUB NOW
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
const confetti = require("canvas-confetti");
import CompanyListItem from "@/components/CompanyListItem";
import HCaptcha from "@/components/HCaptcha";
import TheHeader from "@/components/TheHeader";
import ModalRegistrationRecommendCompany from "@/components/ModalRegistrationRecommendCompany";
import { mapGetters } from "vuex";

import VueHcaptcha from '@hcaptcha/vue-hcaptcha';

export default {
  name: "Registration",
  components: {
    TheHeader,
    CompanyListItem,
    VueHcaptcha,
    HCaptcha,
    ModalRegistrationRecommendCompany,
  },
  data() {
    return {
      isModalVisible: false,
      step: -1,
      // step: 6,
      showMoreInfos: false,
      myConfetti: new Function(),
      isLoadingRegister: false,
      searchCompany: "",
      companySearchResult: [],
      companySearchIsLoading: false,
      isLoadingBetaCheck: false,
      form: {
        betaCode: this.$route.query.betacode,
        betaEmail: this.$route.query.email,
        company: null,
        companyIndex: -1,
        email: "",
        emailRepeat: "",
        password: "",
        passwordRepeat: "",
        firstname: "",
        lastname: "",
        staffnumber: "",
        staffcard_front: false,
        staffcard_back: false,
        termsOfUse: false,
        privacyTerms: false,
        staffcheck: false,
      },
      stepAccessDisabled: true,
      step2disabled: true,
      stepPersonalDisabled: true,
      stepVerifyDisabled: true,
      stepCompleteDisabled: true,
      isFailed: 0,
      isCaptchaComplete: false,
      captchaData: {
        token: null,
        eKey: null
      },
    };
  },
  methods: {
    captchaOnVerify(token, eKey) {
      // console.log("captchaOnVerify()");
      // console.log(token);
      this.isCaptchaComplete = true
      this.captchaData = {
        token: token,
        eKey: eKey
      }
    },
    captchaOnExpired() {
      // console.log("captchaOnExpired()");
      this.isCaptchaComplete = false
    },
    captchaOnError() {
      // console.log("captchaOnError()");
      this.isCaptchaComplete = false
    },
    checkBetacode() {
      if(
        this.form.betaCode.length > 10 && this.form.betaEmail.length > 4 && !this.isLoadingBetaCheck
        ) {
          this.isLoadingBetaCheck = true;

          // console.log(this.captchaData);

          this.$store
          .dispatch("checkBetacode", {email: this.form.betaEmail, code: this.form.betaCode, token: this.captchaData.token})
          .then((a) => {
            this.isLoadingBetaCheck = false;
            this.stepAccessChecker();

            if (a.status == true) {
              if(a.response.status == true) {
                this.companySearchResult.push(a.response.response.company);
                this.form.email = a.response.response.email;
                this.form.emailRepeat = a.response.response.email;
                this.nextStep();
                return;
              }
            }
            this.form.betaCode = "";
            console.error("error:checkBetacode");
            this.isFailed = 801
            // alert('Ungültige Einladung. Prüfe Deine E-Mail Adresse und Deinen Einladungscode. Schreibe uns gerne, falls Du Hilfe brauchst.');
            
          });
      }
    },
    stepAccessChecker() {

      if(typeof this.form !== "undefined") {
        if(typeof this.form.betaEmail !== "undefined" && typeof this.form.betaCode !== "undefined") {
          if(
          this.form.betaCode.length > 10 && this.form.betaEmail.length > 4
          ) {
            this.stepAccessDisabled = false
            return
          }
        }
      }

      // if(
      //   this.form.betaCode.length > 10 && this.form.betaEmail.length > 4
      //   ) {
      //   this.stepAccessDisabled =false
      // } else {
      //   this.stepAccessDisabled =true
      // }

      this.stepAccessDisabled =true
    },
    step2checker() {
      if(
        this.form.email.length > 4 &&
        this.form.email == this.form.emailRepeat &&
        this.form.password.length > 7 &&
        this.form.password == this.form.passwordRepeat
        ) {
        this.step2disabled =false
      } else {
        this.step2disabled =true
      }
    },
    stepPersonalChecker() {
      if(
        this.form.firstname.length > 1 &&
        this.form.lastname.length > 1
        ) {
        this.stepPersonalDisabled =false
      } else {
        this.stepPersonalDisabled =true
      }
    },
    stepVerifyChecker() {
      if(
        this.form.firstname.length > 1 &&
        this.form.lastname.length > 1 &&
        this.form.staffnumber.length > 1
        ) {
        this.stepVerifyDisabled =false
      } else {
        this.stepVerifyDisabled =true
      }
    },
    stepCompleteChecker() {
      if(
        this.form.termsOfUse &&
        this.form.privacyTerms &&
        this.form.staffcheck
        ) {
        this.stepCompleteDisabled =false
      } else {
        this.stepCompleteDisabled =true
      }
    },
    showModal() {
      this.isModalVisible = true;
      document.body.classList.add("modal-open");
    },
    closeModal() {
      this.isModalVisible = false;
      document.body.classList.remove("modal-open");
    },
    searchForCompany() {
      this.companySearchResult = [];
      this.companySearchIsLoading = true;
      // console.log(this.searchCompany);

      if (this.searchCompany) {
        this.$store
          .dispatch("searchForCompany", this.searchCompany)
          .then((a) => {
            this.companySearchIsLoading = false;
            if (a.status == true) {
              this.companySearchResult = a.result;
            } else {
              console.error("Error with searchForCompany");
            }
          });
      } else {
        this.companySearchIsLoading = false;
      }
    },
    selectCompany(value) {
      // console.log(value);
      // console.log(this.companySearchResult[value]);
      this.form.companyIndex = value;
      this.form.company = this.companySearchResult[value];
      this.nextStep();
    },
    lastStep() {
      // console.log(this.form);

      this.isLoadingRegister = true

      this.$store.dispatch("register", this.form).then((a) => {
        
        if (a.status == true) {
          if (a.response.status == true) {
            this.step = this.step + 1;
            this.fireConfetti();
            return;
          } else {
            alert("Es tut uns leid! Bei der Verarbeitung Deiner Daten ist ein Fehler auf unserem Server aufgetreten. Versuche es bitte in ein paar Sekunden erneut, ansonsten kontaktiere uns bitte, damit wir das Problem lösen können.");
          }
        } else {
          alert("Es tut uns leid! Bei der Registrierung ist ein Fehler aufgetreten. Prüfe Deine Internetverbindung und versuche es erneut. Kontaktiere uns gerne, falls Du Hilfe brauchst.");
        }

        this.isLoadingRegister = false
        
        console.error("Error register dispatch")
      });


      return

      if (document.querySelector("#file-input").files.length == 0) {
        alert("Error : No file selected");
        return;
      }

      let file = document.querySelector("#file-input").files[0];
      let allowed_mime_types = ["image/jpeg", "image/png"];
      let allowed_size_mb = 2;

      if (allowed_mime_types.indexOf(file.type) == -1) {
        alert("Error : Incorrect file type");
        return;
      }

      if (file.size > allowed_size_mb * 1024 * 1024) {
        alert("Error : Exceeded size");
        return;
      }

      let data = new FormData();
      data.append("file", document.querySelector("#file-input").files[0]);

      // let request = new XMLHttpRequest();
      // request.open('POST', 'upload.php');
      // request.addEventListener('load', function(e) {
      //   console.log(request.response);
      // });
      // request.send(data);

      
    },
    nextStep() {
      this.step = this.step + 1;
    },
    frameFunction() {
      var myConfetti = this.myConfetti;

      myConfetti({
        particleCount: 5,
        angle: 60,
        spread: 200,
        origin: { x: 0, y: 1.1 },
      });
      // and launch a few from the right edge
      myConfetti({
        particleCount: 5,
        angle: 120,
        spread: 200,
        origin: { x: 1, y: 1.1 },
      });

      myConfetti({
        particleCount: 2,
        angle: 100,
        spread: 300,
        origin: { x: 0.8, y: 1.1 },
      });

      myConfetti({
        particleCount: 2,
        angle: 40,
        spread: 300,
        origin: { x: 0.2, y: 1.1 },
      });
    },
    fireConfetti: function () {
      this.intervalid1 = setInterval(() => {
        this.frameFunction();
      }, 50);
    },
  },
  mounted() {
    this.$store.dispatch("fetchUserProfile");

    var myCanvas = document.getElementById("canvas");
    this.myConfetti = confetti.create(myCanvas, {
      resize: true,
      useWorker: false,
    });

    this.stepAccessChecker();
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
  },
};
</script>

<style scoped>
canvas {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 5;
  pointer-events: none;
}

.badge {
  width: 220px;
  margin: auto;
}

/* .box-selection-option:nth-of-type(2) {
    flex: 1 1 40%;
    min-height: 350px;
}
*/

.box-selection-option .line {
    width: 150px;
    margin: 12px;
}

.card .icon {
    height: 60px;
    width: 60px;
    @apply mx-auto m-6
}

.box-selection-option p {
    font-size: 1.2rem;
} 
</style>

<style>

</style>